import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/home/src/app/[locale]/(main)/components/contact-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/home/src/app/[locale]/(main)/components/customers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/home/src/app/[locale]/(main)/components/hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollArea"] */ "/app/packages/ui/src/components/scroll-area.tsx");
